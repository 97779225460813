import { FormLayout, Modal, TextField, Form, Text, Select } from '@shopify/polaris';
import { useCallback, useEffect, useState } from 'react';
import helpers from 'helpers';
import { lengthLessThan, notEmpty, useField, useForm } from '@shopify/react-form';
import { USER_CAPACITY_LIST } from 'constant';
import __ from 'languages/index';
import { useCreateUser } from 'queries/users.query';

/**
 *   Create upload Modal for Notification
 */

// eslint-disable-next-line import/no-anonymous-default-export

export default function UserNewOrEditModal({
  onClose,
  show
}: {
  onClose: Function;
  show: boolean;
}) {
  const {
    mutateAsync: createEntity,
    isPending: updating,
    isSuccess: updateSuccess
  } = useCreateUser();
  const [user_role_selected, setUser_role_selected] = useState('visitor');
  let allRoles = [];
  for (let roles of USER_CAPACITY_LIST) {
    allRoles.push({
      label: __('user_role_' + roles),
      value: roles
    });
  }
  const useFields = {
    user_email: useField<string>({
      value: '',
      validates: [notEmpty('Email is required!'), inputValue => {
        if (!helpers.isEmail(inputValue)) {
          return 'Your email is not valid!';
        }
      }]
    }),
    display_name: useField<string>({
      value: '',
      validates: [notEmpty('Không được để trống trường này.'), lengthLessThan(250, 'No more than 250 characters.'), inputValue => {
        if (inputValue.length < 2) {
          return 'Your display name is too short!';
        }
      }]
    }),
    password: useField<string>({
      value: '',
      validates: [inputValue => {
        if (inputValue && helpers.isUTF8(inputValue)) return 'Không được sử dụng mã UTF8 ở trường mật khẩu.';
      }]
    })
  };
  const handleSelectRoleChange = useCallback(value => {
    setUser_role_selected(value);
    useFields.display_name.dirty = true;
  }, []);
  const {
    fields,
    submit,
    submitting,
    dirty,
    reset: resetForm,
    submitErrors,
    makeClean
  } = useForm({
    fields: useFields,
    async onSubmit(values) {
      try {
        // create new
        await createEntity({
          user_email: values.user_email,
          display_name: values.display_name,
          user_status: 1,
          user_role: String(user_role_selected),
          password: values.password
        });
      } catch (e: any) {
        if (e.params !== undefined) {
          if (e.params.field !== undefined) {
            useFields[e.params.field].setError(__('check_this_field_again'));
          }
        }
      }
      return {
        status: 'success'
      };
    }
  });
  /* End form */

  useEffect(() => {
    if (updateSuccess) {
      resetForm();
      onClose();
    }
  }, [updateSuccess]);
  const Actual_page = <Modal open={show} onClose={() => onClose()} title={'Tạo mới một User'} primaryAction={{
    content: __('create_user_button_label'),
    disabled: !dirty,
    loading: updating,
    onAction: submit
  }} secondaryActions={[{
    content: 'Close',
    onAction: () => onClose()
  }]}>
      <Modal.Section>
        <Form onSubmit={submit}>
          <FormLayout>
            <TextField autoFocus autoComplete="off" requiredIndicator label="User Email" {...fields.user_email} />

            <TextField label="Tên hiển thị" requiredIndicator autoComplete="off" {...fields.display_name} />
            <TextField label="Mật khẩu" autoComplete="off" {...fields.password} helpText="Tùy chọn điền! Mật khẩu có thể thay đổi trong trang profile của người dùng sau khi bạn tạo." />

            <Select label="User role" options={allRoles} onChange={handleSelectRoleChange} value={String(user_role_selected)} />
          </FormLayout>
        </Form>
      </Modal.Section>
    </Modal>;
  return <>{Actual_page}</>;
}