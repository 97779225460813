import React from 'react';
import { BlockStack, Text, Page, ContextualSaveBar, TextField, FormLayout, Divider, DescriptionList, Autocomplete, LegacyStack, Tag } from '@shopify/polaris';
import { DeleteIcon } from '@shopify/polaris-icons';
import { useCallback, useEffect, useMemo, useState } from 'react';
import __helpers from 'helpers/index';
import dateandtime from 'date-and-time';
import 'media/css/voucher.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { useNotification } from 'context/NotificationContext';
import { useCreateWalletCode, useUpdateWalletCode, useMutateGetWalletCode, useDeleteWalletCode } from 'queries/wallet_code.query';
import __ from 'languages/index';
import { lengthLessThan, notEmpty, notEmptyString, useField, useForm } from '@shopify/react-form';
import { Helmet } from 'react-helmet-async';
import { FormPrompt } from 'components/FormPrompt';
import { USER_CAPACITY_LIST, USER_ACTIVITY_LIST } from 'constant';
import QuickSearchWallet from 'components/quickSearchWallet';
import { useDeleteConfirm } from 'context/DeleteConfirmContext';
export default function WalletCodeNew() {
  const history = useNavigate();
  const {
    addNotification
  } = useNotification();
  const {
    mutateAsync: createWalletCode,
    isSuccess: createSuccess,
    isPending: creating
  } = useCreateWalletCode();
  const {
    mutateAsync: getEntity,
    data: entity
  } = useMutateGetWalletCode();
  const {
    mutateAsync: updateWalletCode,
    isSuccess: updateSuccess,
    isPending: updating
  } = useUpdateWalletCode();
  const {
    mutateAsync: deleteEntity
  } = useDeleteWalletCode();

  /** Delay load lại, vì không delay nó sẽ không hiện đúng phần đã chọn */
  const [getEntitySuccess, setGetEntitySuccess] = useState(false);
  const delayLoading = useCallback(async () => {
    await __helpers.sleep(1000);
    setGetEntitySuccess(true);
  }, []);
  useEffect(() => {
    delayLoading();
  }, []);
  let {
    wallet_code_id
  } = useParams();
  useEffect(() => {
    if (!wallet_code_id) return;
    getEntity(wallet_code_id).catch(e => {});
  }, [wallet_code_id]);
  useEffect(() => {
    if (updateSuccess) {
      addNotification('info', __('updated_successfully'));
      getEntity(wallet_code_id);
    }
  }, [updateSuccess, wallet_code_id]);
  useEffect(() => {
    if (createSuccess) {
      addNotification('info', __('created_successfully'));
    }
  }, [createSuccess]);
  const useFields = {
    wallet_code_name: useField<string>({
      value: entity?.wallet_code_name,
      validates: [notEmpty('Không để trống trường này.'), lengthLessThan(255, 'Chỉ sử dụng 255 ký tự.')]
    }),
    wallet_code_valid_to: useField<string>({
      value: entity?.wallet_code_valid_to ? dateandtime.format(new Date(Number(entity?.wallet_code_valid_to)), 'HH:mm DD-MM-YYYY') : dateandtime.format(new Date(), 'HH:mm DD-MM-YYYY'),
      validates: [notEmptyString('Trường này không được để trống.')]
    }),
    wallet_code_value: useField<string>({
      value: String(entity?.wallet_code_value || '1'),
      validates: [inputVal => {
        if (__helpers.parseNumeric(inputVal) < 1) return 'Phải là một số hợp lệ lớn hơn 1.';
      }, notEmpty('Không được để trống trường này.')]
    }),
    wallet_type_id: useField<string>({
      value: entity?.wallet_type_id,
      validates: [notEmpty('Không được để trống trường này.')]
    }),
    wallet_code_user_role: useField<string>({
      value: entity?.wallet_code_meta?.find(el => el.meta_key === 'user_role')?.meta_value || '',
      validates: []
    }),
    wallet_code_buyer_day_of_register: useField<string>({
      value: entity?.wallet_code_meta?.find(el => el.meta_key === 'buyer_day_of_register')?.meta_value || '0',
      validates: []
    }),
    wallet_code_buyer_minimum_order_number: useField<string>({
      value: entity?.wallet_code_meta?.find(el => el.meta_key === 'buyer_minimum_order_number')?.meta_value || '0',
      validates: []
    }),
    wallet_code_user_activity: useField<string>({
      value: entity?.wallet_code_meta?.find(el => el.meta_key === 'user_activity')?.meta_value || '',
      validates: []
    }),
    wallet_code_user_ignore_activity: useField<string>({
      value: entity?.wallet_code_meta?.find(el => el.meta_key === 'user_ignore_activity')?.meta_value || '',
      validates: []
    })
  };
  const {
    fields,
    submit,
    submitting,
    dirty,
    reset: Userreset
  } = useForm({
    fields: useFields,
    async onSubmit(values) {
      try {
        if (__helpers.isEmpty(entity)) {
          let createdResult = await createWalletCode({
            wallet_code_name: values.wallet_code_name,
            wallet_code_valid_to: new Date(dateandtime.parse(values.wallet_code_valid_to, 'HH:mm DD-MM-YYYY')).getTime(),
            wallet_code_value: __helpers.parseNumeric(values.wallet_code_value),
            wallet_type_id: values.wallet_type_id,
            meta: [{
              meta_key: 'user_role',
              meta_value: String(values.wallet_code_user_role)
            }, {
              meta_key: 'user_activity',
              meta_value: String(values.wallet_code_user_activity)
            }, {
              meta_key: 'user_ignore_activity',
              meta_value: String(values.wallet_code_user_ignore_activity)
            }, {
              meta_key: 'buyer_day_of_register',
              meta_value: values.wallet_code_buyer_day_of_register
            }, {
              meta_key: 'buyer_minimum_order_number',
              meta_value: values.wallet_code_buyer_minimum_order_number
            }]
          });
          history(`/wallet_code/edit/${createdResult.wallet_code_id}`);
        } else {
          await updateWalletCode({
            wallet_code_name: values.wallet_code_name,
            wallet_code_id: wallet_code_id,
            wallet_code_valid_to: new Date(dateandtime.parse(values.wallet_code_valid_to, 'HH:mm DD-MM-YYYY')).getTime(),
            wallet_code_value: __helpers.parseNumeric(values.wallet_code_value),
            wallet_type_id: values.wallet_type_id,
            meta: [{
              meta_key: 'user_role',
              meta_value: String(values.wallet_code_user_role)
            }, {
              meta_key: 'user_activity',
              meta_value: String(values.wallet_code_user_activity)
            }, {
              meta_key: 'user_ignore_activity',
              meta_value: String(values.wallet_code_user_ignore_activity)
            }, {
              meta_key: 'buyer_day_of_register',
              meta_value: values.wallet_code_buyer_day_of_register
            }, {
              meta_key: 'buyer_minimum_order_number',
              meta_value: values.wallet_code_buyer_minimum_order_number
            }]
          });
        }
      } catch (e) {
        if (typeof e.params !== 'undefined' && e.params.field) {
          useFields[e.params.field]?.setError(e.message);
        }
      }
      return {
        status: 'success'
      };
    }
  });

  /******************************** *
   * ADVANCE 1
   ******************************** */
  const deselectedOptions = useMemo(() => USER_CAPACITY_LIST.map(roles => {
    return {
      label: __(`user_role_` + roles),
      value: roles
    };
  }), []);
  const [selectedOptions, setSelectedOptions] = useState<string[]>(String(useFields.wallet_code_user_role.value || '').split(','));
  useEffect(() => {
    let originalValue = entity?.wallet_code_meta?.find(el => el.meta_key === 'user_role')?.meta_value || '';
    setSelectedOptions(originalValue.split(','));
  }, [entity]);
  useEffect(() => {
    useFields.wallet_code_user_role.onChange(selectedOptions.join(','));
  }, [selectedOptions]);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState(deselectedOptions);
  const updateText = useCallback((value: string) => {
    setInputValue(value);
    if (value === '') {
      setOptions(deselectedOptions);
      return;
    }
    const filterRegex = new RegExp(value, 'i');
    const resultOptions = deselectedOptions.filter(option => option.label.match(filterRegex));
    setOptions(resultOptions);
  }, [deselectedOptions]);
  const removeTag = useCallback((tag: string) => () => {
    const options = [...selectedOptions];
    options.splice(options.indexOf(tag), 1);
    setSelectedOptions(options);
  }, [selectedOptions]);
  const verticalContentMarkup = selectedOptions.length > 0 ? <LegacyStack spacing="extraTight" alignment="center">
        {selectedOptions.map(option => {
      if (!option) return null;
      let tagLabel = __(`user_role_` + option);
      return <Tag key={`option${option}`} onRemove={removeTag(option)}>
              {tagLabel}
            </Tag>;
    })}
      </LegacyStack> : null;

  /******************************** *
   * ADVANCE 2
   ******************************** */
  const deselectedOptions2 = useMemo(() => USER_ACTIVITY_LIST.map(activity => {
    return {
      label: __(activity),
      value: activity
    };
  }), []);
  const [selectedOptions2, setSelectedOptions2] = useState<string[]>([]);
  const [inputValue2, setInputValue2] = useState('');
  const [options2, setOptions2] = useState(deselectedOptions2);
  useEffect(() => {
    let originalValue = entity?.wallet_code_meta?.find(el => el.meta_key === 'user_activity')?.meta_value || '';
    setSelectedOptions2(originalValue.split(','));
  }, [entity]);
  useEffect(() => {
    useFields.wallet_code_user_activity.onChange(selectedOptions2.join(','));
  }, [selectedOptions2]);
  const updateText2 = useCallback((value: string) => {
    setInputValue2(value);
    if (value === '') {
      setOptions2(deselectedOptions2);
      return;
    }
    const filterRegex = new RegExp(value, 'i');
    const resultOptions = deselectedOptions2.filter(option => option.label.match(filterRegex));
    setOptions2(resultOptions);
  }, [deselectedOptions2]);
  const removeTag2 = useCallback((tag: string) => () => {
    const options = [...selectedOptions2];
    options.splice(options.indexOf(tag), 1);
    setSelectedOptions2(options);
  }, [selectedOptions2]);
  const verticalContentMarkup2 = selectedOptions2.length > 0 ? <LegacyStack spacing="extraTight" alignment="center">
        {selectedOptions2.map(option => {
      if (!option) return null;
      let tagLabel = __(`user_activity_` + option);
      return <Tag key={`option${option}`} onRemove={removeTag2(option)}>
              {tagLabel}
            </Tag>;
    })}
      </LegacyStack> : null;

  /******************************** *
   * ADVANCE 3
   ******************************** */
  const deselectedOptions3 = useMemo(() => USER_ACTIVITY_LIST.map(activity => {
    return {
      label: __(activity),
      value: activity
    };
  }), []);
  const [selectedOptions3, setSelectedOptions3] = useState<string[]>([]);
  const [inputValue3, setInputValue3] = useState('');
  const [options3, setOptions3] = useState(deselectedOptions3);
  useEffect(() => {
    let originalValue = entity?.wallet_code_meta?.find(el => el.meta_key === 'user_ignore_activity')?.meta_value || '';
    setSelectedOptions3(originalValue.split(','));
  }, [entity]);
  useEffect(() => {
    useFields.wallet_code_user_ignore_activity.onChange(selectedOptions3.join(','));
  }, [selectedOptions3]);
  const updateText3 = useCallback((value: string) => {
    setInputValue3(value);
    if (value === '') {
      setOptions3(deselectedOptions3);
      return;
    }
    const filterRegex = new RegExp(value, 'i');
    const resultOptions = deselectedOptions3.filter(option => option.label.match(filterRegex));
    setOptions3(resultOptions);
  }, [deselectedOptions3]);
  const removeTag3 = useCallback((tag: string) => () => {
    const options = [...selectedOptions3];
    options.splice(options.indexOf(tag), 1);
    setSelectedOptions3(options);
  }, [selectedOptions2]);
  const verticalContentMarkup3 = selectedOptions3.length > 0 ? <LegacyStack spacing="extraTight" alignment="center">
        {selectedOptions3.map(option => {
      if (!option) return null;
      let tagLabel = __(`user_activity_` + option);
      return <Tag key={`option${option}`} onRemove={removeTag3(option)}>
              {tagLabel}
            </Tag>;
    })}
      </LegacyStack> : null;
  const {
    showDeleteConfirm
  } = useDeleteConfirm();
  const handleDelete = useCallback(async () => {
    const confirmed = await showDeleteConfirm('Xóa mã ví này?', 'Bạn có chắc muốn xóa không? Hành động này không thể khôi phục.');
    if (confirmed) {
      // Xử lý hành động xóa
      await deleteEntity(entity?.wallet_code_id);
      history('/wallet_code');
    }
  }, [entity]);
  const mainPage = <Page narrowWidth title={wallet_code_id ? `Cập nhật Wallet Code` : `Tạo mới một Wallet Code`} backAction={{
    content: 'Back',
    onAction: () => history('/wallet_code')
  }} primaryAction={{
    content: 'Xóa',
    icon: DeleteIcon,
    disabled: !entity,
    onAction: () => handleDelete()
  }}>
      <BlockStack gap="400">
        <br />
        <Divider />
        <Text as="h3" variant="headingLg">
          Thông tin chung
        </Text>

        <TextField label="Tên mã (chỉ để phân biệt)" autoComplete="off" {...useFields.wallet_code_name} />
        <TextField label="Mã redeem" helpText={'Đây là mã quy đổi (redeem code) mà khách hàng sẽ sử dụng để đổi lấy giá trị vào trong ví tương ứng.'} autoComplete="off" value={entity?.redeem_code ?? ''} readOnly placeholder="Mã tự sinh khi tạo" />

        <FormLayout.Group>
          <TextField label="Giá trị" autoComplete="off" {...useFields.wallet_code_value} value={__helpers.formatNumberWithCommas(useFields.wallet_code_value.value)} onChange={v => useFields.wallet_code_value.onChange(`` + __helpers.parseNumeric(v))} />
          <QuickSearchWallet title="Ví nhận" current_wallet_id={entity?.wallet_type_id} onClose={wallet_id => useFields.wallet_type_id.onChange(wallet_id)} />
        </FormLayout.Group>

        <FormLayout.Group>
          <TextField label="Đến ngày" autoComplete="off" placeholder="Giờ:phút Ngày-tháng-năm" {...useFields.wallet_code_valid_to} />
        </FormLayout.Group>
      </BlockStack>
      <br />
      <Divider />
      <br />
      <BlockStack gap="400">
        <Text as="h3" variant="headingLg">
          Điều kiện nâng cao
        </Text>

        <DescriptionList items={[{
        term: 'Điều kiện thu đổi',
        description: <BlockStack gap={'400'}>
                  <Autocomplete allowMultiple options={options} selected={selectedOptions} textField={<>
                        <Autocomplete.TextField onChange={updateText} label="Vai trò người mua" value={inputValue} placeholder="Khách hàng, cộng tác viên" verticalContent={verticalContentMarkup} autoComplete="off" />
                      </>} onSelect={setSelectedOptions} listTitle="Danh sách các vai trò" />

                  <TextField placeholder="Để trống để không cài đặt" suffix={'Ngày'} autoComplete="off" label="Số ngày người dùng đăng ký" {...useFields.wallet_code_buyer_day_of_register} />
                  <TextField placeholder="Để trống để không cài đặt" suffix={'Số đơn'} autoComplete="off" label="Người mua đã mua tối thiểu" {...useFields.wallet_code_buyer_minimum_order_number} />

                  <Autocomplete allowMultiple options={options2} selected={selectedOptions2} textField={<>
                        <Autocomplete.TextField onChange={updateText2} label="Người mua đã từng hành động" value={inputValue2} placeholder="LOGIN, BUYGOOD" verticalContent={verticalContentMarkup2} autoComplete="off" />
                      </>} onSelect={setSelectedOptions2} listTitle="Danh sách các hành động" />

                  <Autocomplete allowMultiple options={options3} selected={selectedOptions3} textField={<>
                        <Autocomplete.TextField onChange={updateText3} label="Người mua không được có hành động sau đây (ignore list)" value={inputValue3} placeholder="LOGIN, BUYGOOD" verticalContent={verticalContentMarkup3} autoComplete="off" />
                      </>} onSelect={setSelectedOptions3} listTitle="Danh sách các hành động" />
                </BlockStack>
      }]} />
      </BlockStack>
    </Page>;
  return <>
      <Helmet>
        <title>{wallet_code_id ? `Cập nhật Wallet Code` : `Tạo mới một Wallet Code`}</title>
      </Helmet>
      {getEntitySuccess ? mainPage : <>Loading...</>}

      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <FormPrompt hasUnsavedChanges={dirty} />
      {dirty && <ContextualSaveBar message="Thay đổi của bạn chưa được lưu." saveAction={{
      onAction: submit,
      loading: updating || creating,
      disabled: !dirty
    }} discardAction={{
      content: 'Hoàn tác',
      onAction: Userreset
    }} />}
    </>;
}