import { Autocomplete, Avatar, Button, Icon, Select } from '@shopify/polaris';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { USER_CAPACITY_LIST } from 'constant';
import __ from 'languages/index';
import { SearchIcon } from '@shopify/polaris-icons';
import helpers from 'helpers/index';
import { TypedUser } from 'interface/user.model';
import { PlusCircleIcon } from '@shopify/polaris-icons';
import CustomerNew from 'entities/customer/customer.new';
import { useSearchUser } from 'queries/user.query';
type TypedSearchUser = {
  current_user_id?: bigint | string /** vô dụng, không hoạt động ... */;
  onClose?: (val: TypedUser) => void;
  title: string;
  placeholder?: string;
  /** "user" | "customer" | "agency" | "collaborator" | "sale" | "sale_admin" | "admin" | "visitor" */
  user_role?: string;
  disabled?: boolean;
  support_create_new?: boolean;
  support_filter_user_role?: boolean;
};

/**
 *
 * @param current_user_id bigint
 * @param  onClose callback with bigint userid
 * @returns
 */
export default function QuickSearchUser({
  disabled,
  current_user_id = null,
  user_role = null,
  onClose,
  title,
  placeholder = 'Tìm một người dùng',
  support_create_new = false,
  support_filter_user_role = false
}: TypedSearchUser) {
  /**!SECTION
   * Select người handler
   */

  const {
    mutateAsync: searchUser,
    isPending: userLoading
  } = useSearchUser();

  /**
   * User list
   */
  const [userList, setUserList] = useState<any[]>([]);

  /**
   * Assign cho ai đóa
   */
  const [userSelected, setUserSelected] = useState<string[]>([]);
  const [userQuickSearchText, setUserQuickSearchText] = useState<string>('');
  const [userListForQuickOption, setUserListForQuickOption] = useState([]);
  const [userRoleSelect, setUserRoleSelect] = useState<string>(user_role);
  const onQueryToServer = useCallback(async (keyword: string, userRoleSelect: string) => {
    let data = await searchUser({
      query: keyword,
      user_role: userRoleSelect || undefined,
      sort: 'createdAt:desc',
      limit: 5
    });
    // @ts-ignore
    setUserList(data);
  }, []);
  useEffect(() => {
    onQueryToServer('', userRoleSelect);
  }, [userRoleSelect]);
  useEffect(() => {
    let innerUser = [];
    if (userList) for (let user of userList) {
      let display_name = user.display_name || user.user_email || user.user_phonenumber || user.user_login;
      innerUser.push({
        value: user.user_id,
        label: display_name,
        media: <Avatar source={user.user_avatar} name={display_name} initials={display_name[0]} />
      });
      if (current_user_id && user.user_id === BigInt(current_user_id)) {
        setUserQuickSearchText(user.display_name);
      }
    }
    setUserListForQuickOption(innerUser);
  }, [userList, current_user_id]);
  const onChangeMainQueryCallback = useMemo(() => {
    return helpers.debounce((_value: string, userRoleSelect: string) => {
      onQueryToServer?.call(this, _value, userRoleSelect);
    }, 400);
  }, []);
  const updateUserText = useCallback((value: string) => {
    setUserQuickSearchText(value);
    onChangeMainQueryCallback(value, userRoleSelect);
  }, [userRoleSelect]);
  const findUserSelectedInEntities = useCallback(user_id => {
    if (Array.isArray(userList)) {
      let a = userList.filter(a => a.user_id === user_id);
      return a[0] || null;
    }
  }, [userList]);
  const updateSelection = useCallback((selected: string[]) => {
    const selectedValue = selected.map(selectedItem => {
      const matchedOption = userListForQuickOption.find(option => {
        return option.value.match(selectedItem);
      });
      return matchedOption && matchedOption.label;
    });
    setUserSelected(selected);
    setUserQuickSearchText(selectedValue[0] || '');
    // @ts-ignore
    onClose?.call(this, findUserSelectedInEntities(selected[0]));
  }, [userListForQuickOption]);
  const [showNewCustomerModal, setShowNewCustomerModal] = useState(false);
  const closeNewCustomerModal = useCallback((userData?: TypedUser) => {
    setShowNewCustomerModal(false);
    if (userData) {
      setUserQuickSearchText(String(userData.display_name || userData.user_email || userData.user_login));
      setUserSelected([String(userData.user_id)]);
      onClose?.call(this, userData); // ...
    }
  }, []);
  const textFieldSelectUser = <>
      <Autocomplete.TextField onChange={updateUserText} label={title} disabled={disabled} value={userQuickSearchText} prefix={<Icon source={SearchIcon} tone="base" />} placeholder={placeholder} autoComplete="off" />
    </>;
  const handleSelectChange = useCallback((value: string) => {
    setUserRoleSelect(value);
  }, []);
  const FilterCustomerType = useCallback(() => {
    return <>
        <Select label="Lọc vai trò:" labelInline options={[...[{
        label: 'Tất cả',
        value: ''
      }], ...USER_CAPACITY_LIST.map(item => {
        return {
          label: __(`user_role_` + item),
          value: item
        };
      })]} onChange={handleSelectChange} value={userRoleSelect || undefined} />
        <br />
      </>;
  }, [userRoleSelect]);
  return <>
      {support_filter_user_role && <FilterCustomerType />}
      {support_create_new && <CustomerNew onClose={closeNewCustomerModal} show={showNewCustomerModal} />}
      <Autocomplete actionBefore={support_create_new && {
      accessibilityLabel: 'Tạo người dùng mới',
      badge: {
        tone: 'new',
        content: 'New!'
      },
      content: 'Tạo người dùng mới',
      ellipsis: true,
      icon: PlusCircleIcon,
      onAction: () => {
        setShowNewCustomerModal(true);
        return false;
      }
    }} options={userListForQuickOption} selected={userSelected} onSelect={updateSelection} textField={textFieldSelectUser} loading={userLoading} id="quickSearchUserModalPopover" />
    </>;
}