import { Button, DatePicker, InlineStack, IndexFilters, IndexFiltersProps, Link, TextField, useSetIndexFiltersMode, Text, Select } from '@shopify/polaris';
import { useCallback, useEffect, useMemo, useState } from 'react';
import dateandtime from 'date-and-time';
import helpers from '../../helpers';
import { useDeleteUserMeta, useGetUserMeta, useUpdateUserMeta } from 'queries/user_meta.query';
import useDidMountEffect from 'components/useDidMountEffect';
import __ from 'languages/index';
import { formatDateRange } from 'components/filter.helper';
import * as constants from 'constant';
export default function CustomerFilter({
  onCallback,
  loading
}: {
  onCallback: (output: any) => void;
  loading: boolean;
}) {
  const {
    mutateAsync: getEntity,
    isPending: _loading
  } = useGetUserMeta();
  const {
    mutateAsync: updateEntity,
    isPending: updating
  } = useUpdateUserMeta();
  const {
    mutateAsync: deleteEntity
  } = useDeleteUserMeta();
  const key_for_saving_setting = `tab_customer_index_`;

  /**
   * Load data
   */

  const getItem = useCallback(async (meta_key: string) => {
    meta_key = helpers.parseMetaKey(meta_key);
    return await getEntity(meta_key);
  }, []);
  const removeItem = useCallback(async (meta_key: string) => {
    meta_key = helpers.parseMetaKey(meta_key);
    return await deleteEntity(meta_key);
  }, []);
  const updateItem = useCallback(async (meta_key: string, meta_value: any) => {
    meta_key = helpers.parseMetaKey(meta_key);
    return await updateEntity({
      meta_key: meta_key,
      meta_value: meta_value
    });
  }, []);

  /**
   * Load data
   */

  const rootSearchPath = window.location.search;
  let mainQuery: any = helpers.ExtractUrl(rootSearchPath) || false;
  const {
    query,
    customer_source,
    user_role,
    sort,
    createdAt,
    activity_type,
    activity_source
  } = mainQuery;
  const [sortSelected, setSortSelected] = useState<string[]>([helpers.colonToOldSort(sort) || 'createdAt, desc']);
  const [queryValue, setQueryValue] = useState(query || '');
  const [userRole, setUserRole] = useState(user_role || '');
  const [activityType, setActivityType] = useState(activity_type || '');
  const [activitySource, setActivitySource] = useState(activity_source || '');
  const [sourceWith, setSourceWith] = useState(customer_source || '');
  const [selected, setSelected] = useState(0); // tab selected
  const [dateRanges, setDateRanges] = useState<any>(formatDateRange(createdAt));
  /**
   * Change tab, load new data from url down to filter
   */
  const reloadDataDownFromURL = (__: any) => {
    const {
      query,
      customer_source,
      createdAt,
      activity_type,
      activity_source,
      user_role
    } = __;
    setUserRole(user_role || '');
    setQueryValue(query || '');
    setActivityType(activity_type || '');
    setActivitySource(activity_source || '');
    setSourceWith(customer_source || '');
    setDateRanges(formatDateRange(createdAt));
  };

  /***************************************************************************
  /***************************************************************************
  /***************************************************************************
  * FILTERs
  */

  function getCurrentSetting(): string {
    const rootSearchPath = window.location.search;
    let mainQuery: any = helpers.ExtractUrl(rootSearchPath) || false;
    return JSON.stringify(mainQuery);
  }

  /**
   * reduce loading,
   */

  const onChangeMainQueryCallback = useMemo(() => {
    return helpers.debounce(_value => {
      onCallback?.call(this, _value);
    }, 200);
  }, []);
  useDidMountEffect(() => {
    if (!sortSelected) return;
    let sortString = '';
    if (Array.isArray(sortSelected)) {
      sortString = sortSelected.join('');
    } else {
      sortString = sortSelected;
    }
    onChangeMainQueryCallback({
      sort: helpers.oldSortToColon(sortString)
    });
  }, [sortSelected]);

  /**
   * Hold array of all tab
   */
  const [itemStrings, setItemStrings] = useState(['ALL']);
  useEffect(() => {
    getItem('customer_tabs').then(response => {
      if (helpers.isEmpty(response)) return;
      setItemStrings(response || []);
    }).catch(e => console.log(e, 'e_8d'));
  }, []);

  // save name to
  useDidMountEffect(() => {
    let tabsToString = JSON.stringify(itemStrings);
    updateItem('customer_tabs', tabsToString);
  }, [itemStrings]);

  /**
   * click change tab ...
   * @param index
   * @returns
   */
  const changeTabFilterAction = useCallback(async (index: number, name: string) => {
    if (index === 0) {
      reloadDataDownFromURL({});
      return onChangeMainQueryCallback(false);
    }
    onChangeMainQueryCallback(false);
    await helpers.sleep(100);
    getItem(key_for_saving_setting + name).then((response: any) => {
      if (!response) return;
      onChangeMainQueryCallback(response);
      reloadDataDownFromURL(response);
    }).catch(_ => {});
  }, []);
  const deleteView = (index: number, value: string) => {
    const newItemStrings = [...itemStrings];
    newItemStrings.splice(index, 1);
    setItemStrings(newItemStrings);
    setSelected(0);
    removeItem(key_for_saving_setting + value);
  };
  const duplicateView = async (name: string) => {
    setItemStrings([...itemStrings, name]);
    setSelected(itemStrings.length);
    // clone setting
    updateItem(key_for_saving_setting + name, getCurrentSetting());
    return true;
  };
  const tabs: any[] = Array.isArray(itemStrings) && itemStrings?.map((item, index) => ({
    content: item,
    index,
    onAction: () => {
      changeTabFilterAction(index, item);
    },
    id: `${item}-${index}`,
    isLocked: index === 0,
    actions: index === 0 ? [] : [{
      type: 'rename',
      onAction: () => {},
      onPrimaryAction: async (value: string): Promise<boolean> => {
        const newItemsStrings = tabs?.map((item, idx) => {
          if (idx === index) {
            return value;
          }
          return item.content;
        });
        setItemStrings(newItemsStrings);
        return true;
      }
    }, {
      type: 'duplicate',
      onPrimaryAction: async (value: string): Promise<boolean> => {
        duplicateView(value);
        return true;
      }
    }, {
      type: 'delete',
      onPrimaryAction: async (value: string) => {
        deleteView(index, value);
        return true;
      }
    }]
  }));
  const {
    mode,
    setMode
  } = useSetIndexFiltersMode();
  const handleFiltersQueryChange = useCallback((value: string) => {
    setQueryValue(value);
    onChangeMainQueryCallback({
      query: value,
      page: 1
    });
  }, []);

  /**
   * Xoa hoac huy form search ...
   */
  const handleQueryValueRemove = useCallback(() => {
    setQueryValue('');
    onChangeMainQueryCallback({
      query: '',
      page: 1
    });
  }, []);
  const sortOptions: any[] = [{
    label: 'Ngày tạo',
    value: 'createdAt, asc',
    directionLabel: 'Ascending'
  }, {
    label: 'Ngày tạo',
    value: 'createdAt, desc',
    directionLabel: 'Descending'
  }, {
    label: 'Số tiền chi tiêu',
    value: 'customer_moneyspent_count, asc',
    directionLabel: 'Ascending'
  }, {
    label: 'Số tiền chi tiêu',
    value: 'customer_moneyspent_count, desc',
    directionLabel: 'Descending'
  }, {
    label: 'Số đơn',
    value: 'customer_order_count, asc',
    directionLabel: 'Ascending'
  }, {
    label: 'Số đơn',
    value: 'customer_order_count, desc',
    directionLabel: 'Descending'
  }];
  function disambiguateLabel(key: string, value: string | any): string {
    switch (key) {
      case 'keyword':
        return `Từ khóa: ${value}`;
      case 'user_role':
        return `Loại khách hàng: ${__('user_role_' + (value || 'all'))}`;
      case 'activityType':
        return `Hành động: /${value}`;
      case 'userRoleAdvance':
        return `Phân quyền mở rộng: /${value}`;
      case 'activitySource':
        return `Nguồn hành động: ${value}`;
      case 'customer_source':
        return `Nguồn giới thiệu: ${value}`;
      case 'daterange':
        let from = value[0] ? dateandtime.format(new Date(Number(value[0])), 'DD/MM/YYYY') : '-';
        let to = value[1] ? dateandtime.format(new Date(Number(value[1])), 'DD/MM/YYYY') : '-';
        return `Khoảng thời gian: từ ${from} đến: ${to}`;
      default:
        return value as string;
    }
  }
  const handleActivityWithChange = useCallback((value: string) => {
    setActivityType(value);
    onChangeMainQueryCallback({
      activity_type: value,
      page: 1
    });
  }, []);
  const handleUserRoleWithChange = useCallback((value: string) => {
    setUserRole(value);
    onChangeMainQueryCallback({
      user_role: value,
      page: 1
    });
  }, []);
  const handleSourceActivityWithChange = useCallback((value: string) => {
    setActivitySource(value);
    onChangeMainQueryCallback({
      activity_source: value,
      page: 1
    });
  }, []);
  const handleSourceWithChange = useCallback((value: string) => {
    setSourceWith(value);
    onChangeMainQueryCallback({
      customer_source: value,
      page: 1
    });
  }, []);
  const handleActivityWithRemove = useCallback(() => {
    setActivityType('');
    onChangeMainQueryCallback({
      activity_type: '',
      page: 1
    });
  }, []);
  const handleSourceActivityWithRemove = useCallback(() => {
    setActivitySource('');
    onChangeMainQueryCallback({
      activity_source: '',
      page: 1
    });
  }, []);
  const handleUserRoleWithRemove = useCallback(() => {
    setUserRole('');
    onChangeMainQueryCallback({
      user_role: '',
      page: 1
    });
  }, []);
  const handleSourceWithRemove = useCallback(() => {
    setSourceWith('');
    onChangeMainQueryCallback({
      customer_source: '',
      page: 1
    });
  }, []);
  const handCalendarRemove = useCallback(() => {
    setDateRanges([]);
    onChangeMainQueryCallback({
      createdAt: '',
      page: 1
    });
  }, []);
  const handleFiltersClearAll = useCallback(() => {
    handleQueryValueRemove();
    handleActivityWithRemove();
    handleSourceActivityWithRemove();
    handleSourceWithRemove();
    handCalendarRemove();
    onChangeMainQueryCallback(false);
  }, [handleQueryValueRemove, handleActivityWithRemove, handleSourceActivityWithRemove, handleSourceWithRemove, handCalendarRemove, onChangeMainQueryCallback]);

  /**
   * Date time filling ...
   */

  // date range
  const [{
    month,
    year
  }, setDate] = useState({
    month: new Date().getMonth(),
    year: new Date().getFullYear()
  });
  const [{
    startDate,
    endDate
  }, setDateRange] = useState({
    startDate: new Date().getTime(),
    endDate: new Date().getTime()
  });
  function handleMonthChange(month: number, year: number): any {
    setDate({
      month,
      year
    });
  }
  function handleCalendarChange({
    start,
    end
  }): void {
    let startDate = start.valueOf();
    let endDate = end.valueOf();
    setDateRange({
      startDate,
      endDate
    }); // for calendar
    setDateRanges([startDate, endDate]); // for saving ...
    onChangeMainQueryCallback({
      createdAt: `gte:${startDate},lte:${endDate}`,
      page: 1
    });
  }
  const filters = [{
    key: 'activityType',
    label: 'Hành động',
    filter: <Select value={activityType} label="Hành động" labelInline onChange={handleActivityWithChange} options={[...[{
      label: 'Chọn một hành động',
      value: ''
    }], ...constants.USER_ACTIVITY_LIST.map(el => {
      return {
        label: el,
        value: el
      };
    })]} />,
    shortcut: true
  }, {
    key: 'user_role',
    label: 'Vai trò',
    filter: <Select label="Vai trò của khách hàng" value={userRole} options={[{
      label: __('user_role_all'),
      value: ''
    }, {
      label: __('user_role_customer'),
      value: 'customer'
    }, {
      label: __('user_role_collaborator'),
      value: 'collaborator'
    }, {
      label: __('user_role_agency'),
      value: 'agency'
    }]} onChange={handleUserRoleWithChange} />,
    shortcut: true
  }, {
    key: 'activitySource',
    label: 'Nguồn hành động',
    filter: <TextField label="Nguồn hành động" helpText={'Phân tách nhiều nguồn bằng dấu phẩy'} value={activitySource} onChange={handleSourceActivityWithChange} autoComplete="off" labelHidden />,
    shortcut: true
  }, {
    key: 'customer_source',
    label: 'Nguồn giới thiệu',
    filter: <TextField label="Nguồn giới thiệu" helpText={'Phân tách nhiều nguồn bằng dấu phẩy'} value={sourceWith} onChange={handleSourceWithChange} autoComplete="off" labelHidden />,
    shortcut: true
  }, {
    key: 'daterange',
    label: 'Khoảng thời gian',
    filter: <>
          <DatePicker month={month} year={year} selected={{
        start: new Date(startDate),
        end: new Date(endDate)
      }} onMonthChange={handleMonthChange} onChange={handleCalendarChange} multiMonth={false} allowRange />
        </>,
    shortcut: true
  }];
  const appliedFilters: IndexFiltersProps['appliedFilters'] = [];
  if (!helpers.isEmpty(activityType)) {
    appliedFilters.push({
      key: 'activityType',
      label: disambiguateLabel('activityType', activityType),
      onRemove: handleActivityWithRemove
    });
  }
  if (!helpers.isEmpty(activitySource)) {
    appliedFilters.push({
      key: 'activitySource',
      label: disambiguateLabel('activitySource', activitySource),
      onRemove: handleSourceActivityWithRemove
    });
  }
  // if (!helpers.isEmpty(userRole)) {
  appliedFilters.push({
    key: 'user_role',
    label: disambiguateLabel('user_role', userRole),
    onRemove: handleUserRoleWithRemove
  });
  // }

  // if (!helpers.isEmpty(sourceWith)) {
  // const key = 'customer_source';
  appliedFilters.push({
    key: 'customer_source',
    label: disambiguateLabel('customer_source', sourceWith),
    onRemove: handleSourceWithRemove
  });
  // }
  // if (!helpers.isEmpty(dateRanges)) {
  // const key = 'daterange';
  appliedFilters.push({
    key: 'daterange',
    label: disambiguateLabel('daterange', dateRanges),
    onRemove: handCalendarRemove
  });
  // }

  /**
   * Lưu lại ...
   * @param value
   * @returns
   */
  const onCreateNewView = async (value: string) => {
    setItemStrings([...itemStrings, value]);
    setSelected(itemStrings.length);
    updateItem(key_for_saving_setting + value, getCurrentSetting());
    return true;
  };
  const onHandleSave = useCallback(async () => {
    if (!itemStrings[selected]) return;
    updateItem(key_for_saving_setting + itemStrings[selected], getCurrentSetting());
    return true;
  }, [selected, itemStrings]);
  const primaryActionForFilterFields: IndexFiltersProps['primaryAction'] = selected === 0 ? {
    type: 'save-as',
    onAction: onCreateNewView,
    disabled: false,
    loading: false
  } : {
    type: 'save',
    onAction: onHandleSave,
    disabled: false,
    loading: false
  };

  /***************************************************************************
  /***************************************************************************
  /***************************************************************************
   * FILTERs
   */

  return <>
      <IndexFilters loading={loading || _loading || updating} sortOptions={sortOptions} sortSelected={sortSelected} queryValue={queryValue} queryPlaceholder="Tìm kiếm" onQueryChange={handleFiltersQueryChange} onQueryClear={handleQueryValueRemove} onSort={setSortSelected} primaryAction={primaryActionForFilterFields} cancelAction={{
      onAction: () => {},
      disabled: false,
      loading: false
    }} tabs={tabs} selected={selected} onSelect={setSelected} canCreateNewView={true} onCreateNewView={onCreateNewView} onClearAll={handleFiltersClearAll} mode={mode} setMode={setMode} filters={filters} appliedFilters={appliedFilters} />
      {mode === 'DEFAULT' ? <div className="Polaris-Filters__FiltersWrapper Polaris-Filters__FiltersWrapperWithAddButton filterWrapper">
          <div className="Polaris-Filters__FiltersInner">
            {!helpers.isEmpty(queryValue) ? <span onClick={() => setMode('FILTERING' as any)} className="FilterPill">
                {disambiguateLabel('keyword', queryValue)}
              </span> : null}

            {!helpers.isEmpty(userRole) ? <span onClick={() => setMode('FILTERING' as any)} className="FilterPill">
                {disambiguateLabel('user_role', userRole)}
              </span> : null}

            {!helpers.isEmpty(activityType) ? <span onClick={() => setMode('FILTERING' as any)} className="FilterPill">
                {disambiguateLabel('activityType', activityType)}
              </span> : null}
            {!helpers.isEmpty(activitySource) ? <span onClick={() => setMode('FILTERING' as any)} className="FilterPill">
                {disambiguateLabel('activitySource', activitySource)}
              </span> : null}

            {!helpers.isEmpty(sourceWith) ? <span onClick={() => setMode('FILTERING' as any)} className="FilterPill">
                {disambiguateLabel('customer_source', sourceWith)}
              </span> : null}

            {!helpers.isEmpty(dateRanges) ? <span onClick={() => setMode('FILTERING' as any)} className="FilterPill">
                {disambiguateLabel('daterange', dateRanges)}
              </span> : null}

            {helpers.isEmpty(queryValue) && helpers.isEmpty(userRole) && helpers.isEmpty(activityType) && helpers.isEmpty(activitySource) && helpers.isEmpty(sourceWith) && helpers.isEmpty(dateRanges) ? <InlineStack blockAlign="center" align="start" wrap={false}>
                <Link removeUnderline onClick={() => setMode('FILTERING' as any)}>
                  {'   '}{' '}
                  <Text as="span" variant="bodySm" tone="subdued">
                    Mở bộ lọc (hoặc bấm nút F)
                  </Text>
                </Link>
              </InlineStack> : <Button variant="plain" size="micro" onClick={handleFiltersClearAll}>
                xóa hết
              </Button>}
          </div>
        </div> : null}
    </>;
}