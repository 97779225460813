import { compiledBlock as _compiledBlock } from "million/react";
const CustomerEditInformation_1 = _compiledBlock(_props => <slot>
      {_props.v0}

      {_props.v1}

      <br />
      <br />
      <br />
      <br />
      <br />
    </slot>, {
  name: "CustomerEditInformation_1",
  portals: ["v0", "v1"]
});
const CustomerEditInformation = function CustomerEditInformation({
  onClose,
  entity
}: {
  onClose: Function;
  entity?: TypedUser;
}) {
  const {
    addNotification
  } = useNotification();
  const {
    mutateAsync: updateEntity,
    isPending: updating,
    isSuccess: updateSuccess
  } = useUpdateCustomer();
  /** user group */
  const [selectedUser_to_group, setSelectedUser_to_group] = useState([]);
  const {
    data: userToGroupData,
    isLoading: loadingUserToGroupData
  } = useGetUser_to_groups(entity.user_id);
  const {
    mutateAsync: deleteUser_to_group
  } = useDeleteUser_to_group();
  const {
    mutateAsync: createUser_to_group
  } = useCreateUser_to_group();
  /** Hiển thị cái group đã selected mà mệt ghê ... */
  useEffect(() => {
    if (userToGroupData) {
      let totalS = [];
      for (let d of userToGroupData.body) {
        totalS.push(d.user_group_id);
      }
      setSelectedUser_to_group(totalS);
    }
  }, [userToGroupData]);
  const deleteUserToGroupCallback = useCallback(async id => {
    try {
      await deleteUser_to_group(id);
      addNotification('info', 'Đã xóa người dùng khỏi nhóm');
    } catch (e) {}
  }, []);
  const createUserToGroupCallback = useCallback(async (input: string[]) => {
    try {
      for (let user_group_id of input) {
        await createUser_to_group({
          user_group_id: user_group_id,
          user_id: entity?.user_id
        });
      }
    } catch (e) {}
  }, [entity]);

  /** end user group */

  const {
    mutateAsync: addUserToJob
  } = useAddUserToJob();
  const {
    smUp
  } = useBreakpoints();
  const userJobCallback = useCallback((userJobList: string[]) => {
    addUserToJob({
      user_id: entity?.user_id,
      job_id: userJobList ? userJobList.join(',') : null
    });
  }, [entity]);
  const useFields = {
    user_login: useField<string>({
      value: entity?.user_login,
      validates: [lengthLessThan(60, 'Không được dài hơn 60 ký tự.'), lengthMoreThan(4, 'Không được ngắn hơn 4 ký tự.'), inputValue => {
        if (inputValue && inputValue.length > 1) {
          if (helpers.isUTF8(inputValue)) {
            return 'Không được dùng mã Unicode trong trường này!';
          }
          if (/^[a-zA-Z0-9_.]+$/.exec(inputValue) === null) {
            return 'Chỉ sử dụng chữ cái, số và dấu chấm';
          }
        }
      }]
    }),
    referrer_code: useField<string>({
      value: entity?.referrer_code,
      validates: [inputValue => {
        if (inputValue.length > 0) {
          if (helpers.isUTF8(inputValue)) {
            return 'Không được dùng mã Unicode trong trường này!';
          }
          if (/^[a-zA-Z0-9_.]+$/.exec(inputValue) === null) {
            return 'Chỉ sử dụng chữ cái, số và dấu gạch dưới.';
          }
          if (inputValue.length > 12) {
            return 'Không được dài hơn 12 ký tự.';
          }
        }
      }]
    }),
    user_email: useField<string>({
      value: entity?.user_email || undefined,
      validates: [inputValue => {
        if (inputValue && !helpers.isEmail(inputValue)) {
          return 'Your email is not valid!';
        }
      }]
    }),
    display_name: useField<string>({
      value: entity?.display_name,
      validates: [lengthLessThan(60, 'Tên của bạn quá dài!'), lengthMoreThan(2, 'Tên của bạn quá ngắn!')]
    }),
    bio: useField<string>({
      value: entity?.bio || '',
      validates: [lengthLessThan(250, 'Mô tả quá dài!')]
    }),
    user_address: useField<string>({
      value: entity?.user_address || '',
      validates: [lengthLessThan(250, 'Mô tả quá dài!')]
    }),
    user_job: useField<string>({
      value: '',
      validates: []
    }),
    user_title: useField<string>({
      value: entity?.user_title,
      validates: []
    }),
    user_role: useField<string>({
      value: entity?.user_role,
      validates: []
    }),
    user_role_title: useField<string>({
      value: entity?.user_role_title || undefined,
      validates: []
    }),
    user_birthday: useField<string>({
      value: entity?.user_birthday,
      validates: []
    }),
    user_phonenumber: useField<string>({
      value: entity?.user_phonenumber || undefined,
      validates: [inputValue => {
        if (inputValue) {
          if (!new RegExp('^[0-9]+$', 'g').test(inputValue)) {
            return 'Định dạng số điện thoại không hợp lệ. Chỉ dùng số và dấu cách!';
          }
        }
      }]
    }),
    user_status: useField<number>({
      value: entity?.user_status || 0,
      validates: []
    }),
    user_verified_email: useField<number>({
      value: entity?.user_verified_email || 0,
      validates: []
    }),
    user_verified_phone: useField<number>({
      value: entity?.user_verified_phone || 0,
      validates: []
    }),
    user_verified_profile: useField<number>({
      value: entity?.user_verified_profile || 0,
      validates: []
    }),
    user_address_city: useField<string>({
      value: entity?.user_address_city || '',
      validates: []
    }),
    user_address_district: useField<string>({
      value: entity?.user_address_district || '',
      validates: []
    }),
    user_address_ward: useField<string>({
      value: entity?.user_address_ward || '',
      validates: []
    })
  };
  const {
    fields,
    submit,
    submitting,
    dirty,
    reset: resetForm
  } = useForm({
    fields: useFields,
    async onSubmit(values) {
      try {
        await updateEntity({
          user_id: entity?.user_id,
          referrer_code: values.referrer_code,
          user_email: values.user_email,
          user_status: values.user_status,
          user_verified_email: values.user_verified_email,
          user_verified_phone: values.user_verified_phone,
          user_verified_profile: values.user_verified_profile,
          user_login: values.user_login,
          display_name: values.display_name,
          bio: values.bio,
          user_address: values.user_address,
          user_address_city: values.user_address_city,
          user_address_district: values.user_address_district,
          user_address_ward: values.user_address_ward,
          user_phonenumber: values.user_phonenumber,
          user_job: values.user_job,
          user_title: values.user_title,
          user_role: values.user_role,
          user_role_title: values.user_role_title,
          user_birthday: values.user_birthday || undefined
        });
      } catch (e: any) {
        if (e.params !== undefined) {
          if (e.params.field !== undefined) {
            useFields[e.params.field].setError(__('check_this_field_again'));
          }
        }
      }
      return {
        status: 'success',
        errors: []
      };
    }
  });
  useEffect(() => {
    if (updateSuccess) {
      resetForm();
      onClose();
      addNotification('info', __('update_successfully'));
    }
  }, [updateSuccess]);

  /**
   * DDiaj chir
   */

  const user_address_city_selectType = useFields.user_address_city;
  const user_address_district_selectType = useFields.user_address_district;
  const user_address_ward_selectType = useFields.user_address_ward;

  /**
   * Địa chính
   * Chọn tỉnh, thành phố ...
   */
  const [diachinhCity, setDiachinhCity] = useState<string | undefined>(undefined);
  const [diachinhDistrict, setDiachinhDistrict] = useState<string | undefined>(undefined);
  const [diachinhDistrictList, setDiachinhDistrictList] = useState(undefined);
  const [diachinhWards, setDiachinhWards] = useState<string | undefined>(undefined);
  const [diachinhWardsList, setDiachinhWardsList] = useState(undefined);

  // set default value
  useEffect(() => {
    setDiachinhCity(entity?.user_address_city || undefined);
    setDiachinhDistrict(entity?.user_address_district || undefined);
    setDiachinhWards(entity?.user_address_ward || undefined);
  }, [entity]);
  const diachinhCityCallback = useCallback((_value: string) => {
    setDiachinhCity(_value);
    user_address_city_selectType.onChange(_value);
  }, []);
  const [diachinhCityList, setDiachinhCityList] = useState(undefined);
  useEffect(() => {
    let citys = [{
      label: 'Chọn Tỉnh/thành phố',
      value: ''
    }];
    for (let _city in diachinh) {
      let city = diachinh[_city];
      // @ts-ignore
      citys.push({
        label: city.name,
        value: city.code
      });
    }
    setDiachinhCityList(citys);
  }, []);

  /**
   * Địa chính
   * Chọn Quận Huyện
   */
  const diachinhDistrictCallback = useCallback((_value: string) => {
    setDiachinhDistrict(_value);
    user_address_district_selectType.onChange(_value);
  }, []);
  useEffect(() => {
    if (!diachinhCity) {
      return;
    }

    // setDiachinhDistrict(undefined);
    setDiachinhDistrictList(undefined);
    // setDiachinhWards(undefined);
    setDiachinhWardsList(undefined);
    let quanhuyens = [{
      label: 'Chọn Quận/ Huyện',
      value: ''
    }];
    for (let quanhuyen in diachinh[diachinhCity]?.['quan-huyen']) {
      let quanhuyendata = diachinh[diachinhCity]?.['quan-huyen']?.[quanhuyen];
      quanhuyens.push({
        label: quanhuyendata.name_with_type,
        value: quanhuyendata.code
      });
    }
    setDiachinhDistrictList(quanhuyens);
  }, [diachinhCity]);

  /**
   * Địa chính
   * Chọn xã Phường ...
   */
  const diachinhWardsCallback = useCallback((_value: string) => {
    setDiachinhWards(_value);
    user_address_ward_selectType.onChange(_value);
  }, []);
  useEffect(() => {
    if (!diachinhCity || !diachinhDistrict) {
      setDiachinhWardsList(undefined);
      return;
    }
    if (typeof diachinh[diachinhCity]?.['quan-huyen']?.[diachinhDistrict] === 'undefined') {
      return;
    }
    let xathitrans = [{
      label: 'Chọn Xã/ Thị trấn',
      value: ''
    }];
    for (let xathitran in diachinh[diachinhCity]?.['quan-huyen']?.[diachinhDistrict]?.['xa-phuong']) {
      // @ts-ignore
      let quanhuyendata = diachinh[diachinhCity]?.['quan-huyen']?.[diachinhDistrict]?.['xa-phuong']?.[xathitran];
      // let city = diachinh[_city];
      // @ts-ignore
      xathitrans.push({
        label: quanhuyendata.name_with_type,
        value: quanhuyendata.code
      });
    }
    setDiachinhWardsList(xathitrans);
  }, [diachinhDistrict, diachinhCity]);
  return /*@million jsx-skip*/<CustomerEditInformation_1 v0={<Form onSubmit={submit}>
        <BlockStack gap={{
      xs: '800',
      sm: '400'
    }}>
          <InlineGrid columns={{
        xs: '1fr',
        md: '2fr 5fr'
      }} gap="400">
            <Box as="section" paddingInlineStart={{
          xs: '400',
          sm: '0'
        }} paddingInlineEnd={{
          xs: '400',
          sm: '0'
        }}>
              <BlockStack gap="400">
                <Text as="h3" variant="headingMd">
                  Tùy chọn
                </Text>
                <Text as="p" variant="bodyMd">
                  Các tùy chọn tài khoản.
                </Text>
              </BlockStack>
            </Box>
            <Card roundedAbove="sm">
              <BlockStack gap="400">
                <FormLayout>
                  <Checkbox label="Cho phép khách đăng nhập" helpText="Nếu tắt, khách hàng này không thể đăng nhập nữa." checked={useFields.user_status.value === 1} onChange={e => {
                useFields.user_status.onChange(Number(e));
              }} />

                  <Checkbox label="Xét duyệt số điện thoại" disabled={!entity?.user_phonenumber} helpText="Quy trình này thường là tự động, nhưng một vài trường hợp bạn phải xử lý bằng tay tại đây" checked={useFields.user_verified_phone.value === 1} onChange={e => {
                useFields.user_verified_phone.onChange(Number(e));
              }} />

                  <Checkbox label="Xét duyệt email" disabled={!entity?.user_email} helpText="Quy trình này thường là tự động, nhưng một vài trường hợp bạn phải xử lý bằng tay tại đây" checked={useFields.user_verified_email.value === 1} onChange={e => {
                useFields.user_verified_email.onChange(Number(e));
              }} />
                  <Checkbox label="Xét duyệt profile" helpText="Quy trình này thường là tự động, nhưng một vài trường hợp bạn phải xử lý bằng tay tại đây" checked={useFields.user_verified_profile.value === 1} onChange={e => {
                useFields.user_verified_profile.onChange(Number(e));
              }} />
                </FormLayout>
              </BlockStack>
            </Card>
          </InlineGrid>

          {smUp ? <Divider /> : null}
          <InlineGrid columns={{
        xs: '1fr',
        md: '2fr 5fr'
      }} gap="400">
            <Box as="section" paddingInlineStart={{
          xs: '400',
          sm: '0'
        }} paddingInlineEnd={{
          xs: '400',
          sm: '0'
        }}>
              <BlockStack gap="400">
                <Text as="h3" variant="headingMd">
                  Nhóm người dùng
                </Text>
                <Text as="p" variant="bodyMd">
                  Người dùng / khách hàng có thể tham gia rất nhiều nhóm một lúc, mỗi nhóm có một đặc quyền riêng.
                </Text>
              </BlockStack>
            </Box>
            <Card roundedAbove="sm">
              <InlineStack gap="400" align="start" blockAlign="center">
                {loadingUserToGroupData ? <SkeletonBodyText /> : userToGroupData?.body && userToGroupData?.body?.map(el => {
              let {
                user_group_data
              } = el;
              return <InlineStack gap="200">
                        <Button variant="plain" icon={XIcon} onClick={() => deleteUserToGroupCallback(el.id)}></Button>
                        <Thumbnail size="small" source={helpers.getMediaLink(user_group_data.user_group_thumbnail ? user_group_data.user_group_thumbnail_to_media.media_thumbnail ? user_group_data.user_group_thumbnail_to_media.media_thumbnail['128x128'] : user_group_data.user_group_thumbnail_to_media.media_url : null)} alt={''} />
                        <>{user_group_data.user_group_name}</>
                      </InlineStack>;
            })}
              </InlineStack>
              <br />
              <QuickSearchUserGroup displaySelected={false} onClose={EEAA => createUserToGroupCallback(EEAA)} current_user_group_id={selectedUser_to_group} />
            </Card>
          </InlineGrid>

          {smUp ? <Divider /> : null}
          <InlineGrid columns={{
        xs: '1fr',
        md: '2fr 5fr'
      }} gap="400">
            <Box as="section" paddingInlineStart={{
          xs: '400',
          sm: '0'
        }} paddingInlineEnd={{
          xs: '400',
          sm: '0'
        }}>
              <BlockStack gap="400">
                <Text as="h3" variant="headingMd">
                  Thông tin xác minh
                </Text>
                <Text as="p" variant="bodyMd">
                  Người dùng yêu cầu xác minh tài khoản, và bạn có thể duyệt tại đây.
                </Text>
              </BlockStack>
            </Box>
            <Card roundedAbove="sm">
              <BlockStack gap="400">
                <UserVerifiedData user_id={entity?.user_id} />
              </BlockStack>
            </Card>
          </InlineGrid>

          {smUp ? <Divider /> : null}

          <InlineGrid columns={{
        xs: '1fr',
        md: '2fr 5fr'
      }} gap="400">
            <Box as="section" paddingInlineStart={{
          xs: '400',
          sm: '0'
        }} paddingInlineEnd={{
          xs: '400',
          sm: '0'
        }}>
              <BlockStack gap="400">
                <Text as="h3" variant="headingMd">
                  Thông tin chung
                </Text>
                <Text as="p" variant="bodyMd">
                  Càng chi tiết càng tốt.
                </Text>
              </BlockStack>
            </Box>
            <Card roundedAbove="sm">
              <BlockStack gap="400">
                <FormLayout>
                  <TextField autoFocus autoComplete="off" label="Username" helpText={<p>User name không được phép trùng lặp với bất kỳ một tài khoản nào!</p>} {...fields.user_login} />
                  <TextField autoComplete="off" label="Mã giới thiệu" maxLength={12} helpText={<p>Không nên đổi mã nhiều lần, dẫn tới mã cũ bị vô hiệu. Mã này phải độc nhất.</p>} {...fields.referrer_code} />

                  <TextField label="Tên hiển thị" autoComplete="off" {...fields.display_name} connectedLeft={<Select value={useFields.user_title.value} options={[{
                value: '',
                label: 'Không set'
              }, {
                value: 'mr',
                label: 'Anh'
              }, {
                value: 'miss',
                label: 'Cô'
              }, {
                value: 'mrs',
                label: 'Chị'
              }, {
                value: 'madam',
                label: 'Quý bà'
              }, {
                value: 'sir',
                label: 'Quý ông'
              }]} onChange={e => useFields.user_title.onChange(e)} label={''} />} />

                  <Select label="Vai trò" options={[{
                label: __('user_role_customer'),
                value: 'customer'
              }, {
                label: __('user_role_collaborator'),
                value: 'collaborator'
              }, {
                label: __('user_role_agency'),
                value: 'agency'
              }]} onChange={a => useFields.user_role.onChange(a)} value={String(useFields.user_role.value)} />

                  <TextField label="Chức vụ trên hệ thống" autoComplete="off" {...fields.user_role_title} clearButton onClearButtonClick={() => fields.user_role_title.onChange('')} />

                  <DateTimeInput onDateChange={({
                day,
                month,
                year
              }) => {
                fields.user_birthday.onChange(`${year}-${month}-${day}`);
              }} label={'Sinh nhật'} initialDate={fields.user_birthday.defaultValue} />
                  <MultiJob onClose={userJobCallback} current_value={entity?.user_to_job} />
                  <TextField maxLength={250} max={250} showCharacterCount={true} autoComplete="off" label="Giới thiệu ngắn" {...fields.bio} multiline={3} />
                </FormLayout>
              </BlockStack>
            </Card>
          </InlineGrid>

          {smUp ? <Divider /> : null}
          <InlineGrid columns={{
        xs: '1fr',
        md: '2fr 5fr'
      }} gap="400">
            <Box as="section" paddingInlineStart={{
          xs: '400',
          sm: '0'
        }} paddingInlineEnd={{
          xs: '400',
          sm: '0'
        }}>
              <BlockStack gap="400">
                <Text as="h3" variant="headingMd">
                  Thông tin liên hệ
                </Text>
                <Text as="p" variant="bodyMd">
                  Càng nhiều thông tin càng tốt
                </Text>
              </BlockStack>
            </Box>
            <Card roundedAbove="sm">
              <BlockStack gap="400">
                <FormLayout>
                  <TextField label="Số điện thoại" autoComplete="off" helpText="Ví dụ: 0906111111" {...fields.user_phonenumber} />

                  <TextField label="Email" autoComplete="off" helpText="Ví dụ: a@gmail.com" {...fields.user_email} />

                  <TextField label="Địa chỉ" autoComplete="off" {...fields.user_address} />

                  <FormLayout.Group condensed>
                    <Select key={'ahjfkdgf'} label="Tỉnh/Thành phố" options={diachinhCityList} value={diachinhCity} onChange={diachinhCityCallback} />
                    <Select key={'ahjfdsfsgkdgf'} label="Quận/ huyện" options={diachinhDistrictList} value={diachinhDistrict} onChange={diachinhDistrictCallback} />
                    <Select key={'ahjfksdgsdgsw4dgf'} label="Xã/Thị trấn" options={diachinhWardsList} value={diachinhWards} onChange={diachinhWardsCallback} />
                  </FormLayout.Group>
                </FormLayout>
              </BlockStack>
            </Card>
          </InlineGrid>
        </BlockStack>
      </Form>} v1={dirty && <ContextualSaveBar message="Thay đổi chưa được lưu" saveAction={{
    content: __('update_label_button'),
    onAction: () => submit(),
    loading: updating
  }} discardAction={{
    content: 'Thu hồi',
    onAction: () => resetForm()
  }} />} />;
};
import { block as _block } from "million/react";
import { Box, Form, Card, Divider, FormLayout, InlineGrid, Select, Text, TextField, BlockStack, useBreakpoints, Button, Checkbox, InlineStack, ContextualSaveBar, SkeletonBodyText, Thumbnail } from '@shopify/polaris';
import { useCallback, useEffect, useState } from 'react';
import helpers from 'helpers';
import diachinh from 'config/diachinh.json';
import { XIcon } from '@shopify/polaris-icons';
import { lengthLessThan, lengthMoreThan, useField, useForm } from '@shopify/react-form';
import { TypedUser } from 'interface/user.model';
import MultiJob from 'components/multiJob';
import DateTimeInput from 'components/dateTimeInput';
import __ from 'languages/index';
import { useAddUserToJob } from 'queries/user.query';
import UserVerifiedData from './customer.verified.data';
import QuickSearchUserGroup from 'entities/user_group/quickSearchUserGroup';
import { useCreateUser_to_group, useDeleteUser_to_group, useGetUser_to_groups } from 'queries/user_to_group.query';
import { useNotification } from 'context/NotificationContext';
import { useUpdateCustomer } from 'queries/customer.query';

/**
 *   Create upload Modal for Notification
 */

// eslint-disable-next-line import/no-anonymous-default-export

export default CustomerEditInformation;