import { compiledBlock as _compiledBlock } from "million/react";
const WalletCodeList_1 = _compiledBlock(_props => <slot>
      {_props.v0}
      {_props.v1}

      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </slot>, {
  name: "WalletCodeList_1",
  portals: ["v0", "v1"]
});
const WalletCodeList = function WalletCodeList() {
  const history = useNavigate();
  const rootSearchPath = window.location.search;
  let StringQuery: any = helpers.ExtractUrl(rootSearchPath) || false;
  const initialQuery = {
    query: '',
    page: 1,
    limit: 40,
    sort: 'createdAt:desc'
  };
  const [mainQuery, setMainQuery] = useState<any>({
    ...initialQuery,
    ...StringQuery
  });

  /**
   * Change page number
   */
  const onChangePagination = useCallback((numPage: number, limit: number) => {
    setMainQuery(prevMainQuery => ({
      ...prevMainQuery,
      page: numPage,
      limit: limit
    }));
  }, []);

  /**
   * I do not know ...
   * Bug: In React, do NOT remove this code
   */
  const filterCallback = useCallback((_value: any) => {
    if (_value === false) {
      return setMainQuery(initialQuery);
    } else {
      setMainQuery(oldValue => {
        return {
          ...oldValue,
          ..._value
        };
      });
    }
  }, []);
  const {
    data,
    isLoading: loading,
    isFetched,
    refetch: getEntities
  } = useGetWalletCodes(mainQuery);
  const [totalItems, setTotalItems] = useState(0);
  const [records, setRecords] = useState<TypedWalletCode[]>([]);
  useEffect(() => {
    if (data) {
      let {
        entities,
        totalItems
      } = data;
      setRecords(entities);
      setTotalItems(totalItems);
    }
  }, [data]);
  useEffect(() => {
    let buildURLSearch = helpers.buildEndUrl(mainQuery);
    if (window.location.search !== buildURLSearch) {
      window.history.replaceState(null, 'Wallet Code', '/wallet_code' + buildURLSearch);
    }
    getEntities();
  }, [mainQuery]);
  const {
    lgDown
  } = useBreakpoints();
  const fixedFirstColumns = lgDown ? 2 : 0;

  // clone
  const {
    mutateAsync: cloneWalletCode,
    isPending,
    isSuccess
  } = useCloneWalletCode();
  const cloneWalletCodeCallback = useCallback(async (wallet_code_id: string) => {
    // wallet_code_id
    try {
      let cloneData = await cloneWalletCode(wallet_code_id);
      history(`/wallet_code/edit/${cloneData.wallet_code_id}`);
    } catch (e) {}
  }, []);
  return /*@million jsx-skip*/<WalletCodeList_1 v0={<Helmet>
        <title>Quản lý mã ví</title>
      </Helmet>} v1={<Page title="Quản lý mã ví" subtitle="Mã ví (wallet code) là mã có thể thu đổi thành giá trị vào trong ví tương ứng." primaryAction={{
    content: 'Thêm mới mã ví',
    icon: PlusIcon,
    onAction: () => history('/wallet_code/new')
  }}>
        <Card padding={'0'}>
          <SimpleFilter onCallback={filterCallback} options={[{
        label: 'Đã sử dụng',
        field: 'is_used',
        value: '1'
      }, {
        label: 'Chưa sử dụng',
        field: 'is_used',
        value: '0'
      }]} loading={false} sortField={[{
        label: 'Hạn dùng',
        field: 'wallet_code_valid_to'
      }, {
        label: 'Giá trị',
        field: 'wallet_code_value'
      }]} />
          <BlockStack gap="400">
            {records && helpers.isEmpty(records) && !loading && <BlockStack gap={'400'} align="center" inlineAlign="center">
                <Text as="h3" variant="headingMd">
                  Chỗ này trống trơn
                </Text>
                <div style={{
            maxWidth: 250
          }}>
                  <Lottie animationData={voucher_empty} loop={true} />
                </div>
              </BlockStack>}
            {records && Array.isArray(records) && records.length > 0 && <DataTable columnContentTypes={['text', 'text', 'text', 'numeric', 'numeric']} headings={['Tên', 'Mã', 'Giá trị quy đổi', 'Người sử dụng', 'Kết thúc', 'Ngày tạo', '']} verticalAlign="middle" rows={records.map((element, index) => {
          let {
            wallet_code_id,
            redeem_code,
            wallet_code_name,
            wallet_code_meta,
            wallet_code_valid_to,
            wallet_code_value,
            wallet_type,
            wallet_type_id,
            is_used,
            used_by_to_user,
            createdAt
          } = element;
          return [<Button icon={WalletIcon} textAlign="left" onClick={() => history(`/wallet_code/edit/${wallet_code_id}`)} variant="plain" disabled={!!is_used}>
                      {wallet_code_name}
                    </Button>, redeem_code, helpers.formatNumberWithCommas(wallet_code_value) + ' ' + wallet_type?.wallet_sign, used_by_to_user ? <Link url={`/customer/view/${used_by_to_user?.user_id}`}>{used_by_to_user?.display_name}</Link> : '--', dateandtime.format(new Date(Number(wallet_code_valid_to)), 'HH:mm DD-MM-YYYY'), dateandtime.format(new Date(Number(createdAt)), 'HH:mm DD-MM-YYYY'), <Button icon={DuplicateIcon} variant="plain" onClick={() => cloneWalletCodeCallback(wallet_code_id)}></Button>];
        })} sortable={[false, false, false, false, false]} defaultSortDirection="descending" initialSortColumnIndex={4} stickyHeader fixedFirstColumns={fixedFirstColumns} />}
          </BlockStack>
        </Card>

        <br />
        {totalItems > 0 ? <Pagination TotalRecord={totalItems} onChange={onChangePagination} pageSize={Number(mainQuery?.limit)} activeCurrentPage={Number(mainQuery?.page)} /> : null}
      </Page>} />;
};
import { block as _block } from "million/react";
import { BlockStack, Text, Link, Page, DataTable, useBreakpoints, InlineStack, Select, Button, Card } from '@shopify/polaris';
import React, { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { PlusIcon, WalletIcon, DuplicateIcon } from '@shopify/polaris-icons';
import 'media/css/voucher.scss';
import Pagination from 'components/pagination';
import helpers from 'helpers/index';
import { useNavigate } from 'react-router-dom';
import { useGetWalletCodes, TypedWalletCode, useCloneWalletCode } from 'queries/wallet_code.query';
import dateandtime from 'date-and-time';
import voucher_empty from 'media/lottie_files/voucher_empty.json';
import Lottie from 'lottie-react';
import { USER_CAPACITY_LIST } from 'constant/index';
import __ from 'languages/index';
import SimpleFilter from 'components/simpleFilter';
export default WalletCodeList;