import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import helpers from "helpers/index";
import queryClient from 'queries';


type TypedDateFilter = {
    createdAt?: string | number;
    [propName: string]: any;
};


export function useGetTop5(dateobject: TypedDateFilter) {
    return useQuery({
        queryKey: ['analytics/getTop5'],
        queryFn: () => axios.get<any>(`/analytics/top5${helpers.buildEndUrl(dateobject)}`).then((response) => response.data),
        retry: 1,
        refetchOnWindowFocus: true,
    });
}

export function useGetMyPerformance(user_id: string) {
    return useQuery({
        queryKey: ['analytics/getMyPerformance'],
        queryFn: () => axios.get<any>(`/analytics/my_performance?user_id=${user_id}`).then((response) => response.data),
        retry: 1,
        refetchOnWindowFocus: true,
    });
}


export function useGetStaffPerformance(dateobject: TypedDateFilter) {
    return useQuery({
        queryKey: ['analytics/getStaffPerformance'],
        queryFn: () => axios.get<any>(`/analytics/staff_performance${helpers.buildEndUrl(dateobject)}`).then((response) => response.data),
        retry: 1,
        refetchOnWindowFocus: true,
    });
}

export function useGetStaffPerformanceLine(dateobject: TypedDateFilter) {
    return useQuery({
        queryKey: ['analytics/getStaffPerformanceLine'],
        queryFn: () => axios.get<any>(`/analytics/staff_performance_line${helpers.buildEndUrl(dateobject)}`).then((response) => response.data),
        retry: 1,
        refetchOnWindowFocus: true,
    });
}


/**
 * Lấy thông tin cơ bản trong tháng ...
 */

export function useGetGeneralAnalytics() {
    return useQuery({
        queryKey: ['analytics/generalAnalytics'],
        queryFn: () => axios.get<any>(`/analytics/general`).then((response) => response.data),
        retry: 1,
        refetchOnWindowFocus: true,
    });
}


/**
 * Lấy toàn bộ thông tin và báo cáo lifetime ...
 */

export function useGetLifetimeAnalytics() {
    return useQuery({
        queryKey: ['analytics/lifetimeAnalytics'],
        queryFn: () => axios.get<any>(`/analytics/lifetime`).then((response) => response.data),
        retry: 1,
        refetchOnWindowFocus: true,
    });
}


/**
 * Lấy thông tin cơ bản trong tháng ...
 */

export function useGetCustomerGeneralAnalytics(dateobject: TypedDateFilter) {
    return useQuery({
        queryKey: ['analytics/getCustomerGeneralAnalytics'],
        queryFn: () => axios.get<any>(`/analytics/customers${helpers.buildEndUrl(dateobject)}`).then((response) => response.data),
        retry: 1,
        refetchOnWindowFocus: true,
    });
}