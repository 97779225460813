import { DataTable, EmptyState, Layout, Page, Text, Badge, Link, InlineStack, Avatar, Card } from '@shopify/polaris';
import { PlusIcon } from '@shopify/polaris-icons';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import emptyIMG from '../../media/images/empty.png';
import Pagination from '../../components/pagination';
import helpers from '../../helpers';
import dateandtime from 'date-and-time';
import UserNewModal from './users.create.new.modal';
import StarRating from 'components/starRating';
import SimpleFilter from 'components/simpleFilter';
import { USER_CAPACITY_LIST } from 'constant';
import __, { ___ } from 'languages/index';
import { Helmet } from 'react-helmet-async';
import __helpers from '../../helpers';
import { useGetUsers } from 'queries/users.query';
export default function UsersManagement() {
  const [entities, setEntities] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const history = useNavigate();

  /**
   * If user apply filter, it will add to URL, then parse URL back to initial state
   */
  let {
    search
  } = useLocation();
  let StringQuery: any = helpers.ExtractUrl(search);
  const initialQuery = {
    query: '',
    page: 1,
    limit: 20,
    sort: 'createdAt: desc',
    user_role: ''
  };
  const [mainQuery, setMainQuery] = useState({
    ...initialQuery,
    ...StringQuery
  });
  const {
    data,
    refetch: getEntities,
    isLoading: loading
  } = useGetUsers(mainQuery);
  useEffect(() => {
    if (data) {
      let {
        body,
        totalItems
      } = data;
      setEntities(body);
      setTotalItems(totalItems);
    }
  }, [data]);

  /**
   * Change page number
   */
  const onChangePagination = useCallback((numPage: number, limit: number) => {
    setMainQuery(prevMainQuery => ({
      ...prevMainQuery,
      page: numPage,
      limit: limit
    }));
  }, []);

  /**
   * I do not know ...
   * Bug: In React, do NOT remove this code
   */
  const filterCallback = useCallback((_value: any) => {
    if (_value === false) {
      return setMainQuery(initialQuery);
    } else {
      setMainQuery(oldValue => {
        return {
          ...oldValue,
          ..._value
        };
      });
    }
  }, []);

  /**
   * New model
   */

  const [newModelactive, setNewModelactive] = useState(false);
  const toggleNewActive = useCallback(() => setNewModelactive(active => !active), []);
  useEffect(() => {
    let buildURLSearch = helpers.buildEndUrl(mainQuery);
    if (search !== buildURLSearch) history('/users' + buildURLSearch);
    getEntities(mainQuery);
  }, [mainQuery]);
  const emptyData = <EmptyState heading="No users here!" image={emptyIMG}>
      <p>Oh! There is no record here! Try remove filter or add a new record!</p>
    </EmptyState>;
  const handleSort = useCallback((index, direction) => {
    let _direction = direction === 'descending' ? 'desc' : 'asc';
    let sort = '';
    switch (index) {
      case 0:
        sort = 'user_id:' + _direction;
        break;
      case 2:
        sort = 'user_role:' + _direction;
        break;
      case 3:
        sort = 'user_status:' + _direction;
        break;
      case 4:
        sort = 'user_achievement_count:' + _direction;
        break;
      case 5:
        sort = 'user_rate:' + _direction;
        break;
      case 6:
        sort = 'user_rate_count:' + _direction;
        break;
      case 7:
        sort = 'createdAt:' + _direction;
        break;
      default:
        sort = 'lastActive' + _direction;
    }
    setMainQuery({
      ...mainQuery,
      sort: sort
    });
  }, [entities]);
  const renderItem = (users: any, index) => {
    const {
      user_id,
      user_status,
      user_email,
      user_login,
      user_role,
      user_avatar,
      display_name,
      user_phonenumber,
      user_rate,
      user_rate_count,
      user_achievement_count,
      lastActive,
      createdAt
    } = users;
    const user_display_name = display_name || user_email || user_phonenumber || 'noname';
    return [<InlineStack gap={'200'} align="start" blockAlign="center">
        <Avatar source={user_avatar ? __helpers.getMediaLink(user_avatar) : null} name={user_display_name} initials={user_display_name.charAt(0)} size="sm" />
        <Link key={user_id} onClick={() => history('/users/view/' + user_id)}>
          {user_display_name}
        </Link>
      </InlineStack>, <div>{user_display_name}</div>, user_role, <div>{user_status ? <Badge tone="success">active</Badge> : <Badge tone="warning">deactive</Badge>}</div>, user_achievement_count, user_rate > 0 ? <StarRating key={index + '_voting_usr'} num={user_rate} /> : null, user_rate_count, <time>{createdAt ? dateandtime.format(new Date(Number(createdAt)), 'DD-MM-YYYY HH:mm:ss') : '-'}</time>, <time>{lastActive ? dateandtime.format(new Date(Number(lastActive)), 'DD-MM-YYYY HH:mm:ss') : '-'}</time>];
  };
  const UsersList = <>
      <SimpleFilter loading={loading} onCallback={filterCallback} options={USER_CAPACITY_LIST.map(el => {
      return {
        label: __(`user_role_` + el),
        value: el,
        field: 'user_role'
      };
    })} sortField={[{
      label: 'Tình trạng',
      field: 'user_status'
    }, {
      label: 'Huy chương',
      field: 'user_achievement_count'
    }]} />

      {entities.length > 0 ? <DataTable sortable={[true, false, true, true, true, true, true, true, true]} defaultSortDirection="descending" initialSortColumnIndex={0} onSort={handleSort} columnContentTypes={['text', 'text', 'text', 'text', 'numeric', 'text', 'numeric', 'text', 'text']} headings={['Email', 'Name', 'Role', 'Status', 'Achievement', 'Rating', 'Rating count', 'Join at', 'Last login']} rows={entities?.map(renderItem)} hideScrollIndicator footerContent={___('Show page {display_page_number} of {total_record_number} results', {
      display_page_number: <Text as="strong">{mainQuery?.page || 1}</Text>,
      total_record_number: <Text as="strong">{totalItems}</Text>
    })} /> : emptyData}
    </>;
  const Actual_page = <>
      <Helmet>
        <title>Quản lý Users</title>
      </Helmet>
      <Page fullWidth title="Users" subtitle="User management" primaryAction={{
      content: 'Tạo tài khoản',
      disabled: false,
      icon: PlusIcon,
      onAction: toggleNewActive
    }}>
        <Layout>
          <Layout.Section>
            <Card padding={'0'}>{UsersList}</Card>
            <br />
            {totalItems > mainQuery.limit ? <Pagination TotalRecord={totalItems} activeCurrentPage={mainQuery.page} pageSize={mainQuery.limit} onChange={onChangePagination} /> : null}
          </Layout.Section>
        </Layout>
      </Page>
    </>;
  return <>
      {Actual_page}
      {<UserNewModal show={newModelactive} onClose={() => setNewModelactive(false)} />}
    </>;
}