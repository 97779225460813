import { useParams } from 'react-router-dom';
import Theme404 from '../../layout/404';
import AffiliateList from './affiliate.list';
import AffiliateView from './affiliate.view';
import AffiliateSetup from './affiliate.setup';
import AffiliateDefaultSetup from './affiliate_default.setup';
import Capacity from 'components/Capacity';

/**
 *   Create index file for affiliate
 */

export default function AffiliateIndex() {
  let {
    slug = 'list'
  } = useParams();
  let ActualPage: any;
  switch (slug) {
    case 'list':
      ActualPage = <AffiliateList />;
      break;
    case 'view':
      ActualPage = <AffiliateView />;
      break;
    case 'setup':
      ActualPage = <Capacity current_user_can={'admin'} onFail={<Theme404 />}>
          <AffiliateSetup />
        </Capacity>;
      break;
    case 'default_setup':
      ActualPage = <Capacity current_user_can={'admin'} onFail={<Theme404 />}>
          <AffiliateDefaultSetup />
        </Capacity>;
      break;
    default:
      ActualPage = <Theme404 />;
      break;
  }
  return ActualPage;
}